.Loader {
  width: 100%;
  text-align: center;
  position: relative;
  max-width: 680px;
  margin: 0 auto;
}
.Loader-wrapper {
  position: relative;
  display: inline-block;
}
.Loader-icon > div:first-of-type {
  width: 0px;
  height: 0px;
  border-right: 25px solid transparent;
  border-top: 25px solid #00aa46;
  border-left: 25px solid #00aa46;
  border-bottom: 25px solid #00aa46;
  border-radius: 25px;
  position: relative;
  left: -30px;
  z-index: 1;
  animation: pacmanLoaderUp 0.5s 0s infinite;
}
.Loader-icon > div:nth-child(2) {
  width: 0px;
  height: 0px;
  border-right: 25px solid transparent;
  border-top: 25px solid #00aa46;
  border-left: 25px solid #00aa46;
  border-bottom: 25px solid #00aa46;
  border-radius: 25px;
  margin-top: -50px;
  position: relative;
  left: -30px;
  z-index: 1;
  animation: pacmanLoaderBalls 1s -1.32s infinite linear;
  animation: pacmanLoaderDown 0.5s 0s infinite;
}
.Loader-icon > div:nth-child(2):before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  top: -20px;
  left: -10px;
  top: -18px;
  left: -7px;
  z-index: 1;
}
.Loader-icon > div:nth-child(2):after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  top: -20px;
  left: -10px;
}
.Loader-icon > div:nth-child(3) {
  background-color: #00aa46;
  animation: pacmanLoaderBalls 1s -0.99s infinite linear;
}
.Loader-icon > div:nth-child(4) {
  background-color: #f4f5eb;
  animation: pacmanLoaderBalls 1s -0.66s infinite linear;
}
.Loader-icon > div:nth-child(5) {
  background-color: #005537;
  animation: pacmanLoaderBalls 1s -0.33s infinite linear;
}
.Loader-icon > div:nth-child(6) {
  background-color: #aad23c;
  animation: pacmanLoaderBalls 1s 0s infinite linear;
}
.Loader-icon > div:nth-child(3),
.Loader-icon > div:nth-child(4),
.Loader-icon > div:nth-child(5),
.Loader-icon > div:nth-child(6) {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 25px;
  left: 70px;
  transform: translate(0, -6.25px);
}

@keyframes pacmanLoaderUp {
  0% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes pacmanLoaderDown {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes pacmanLoaderBalls {
  75% {
    opacity: 0.7;
  }
  100% {
    transform: translate(-100px, -6.25px);
  }
}

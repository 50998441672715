@use './scss/base';
@use '~@coopbetala/coop-digital-react-ui/dist/scss/base/root';

html {
  font-family: 'Akkurat', sans-serif;
}

body {
  background: var(--cui-clr-bg-primary);
}

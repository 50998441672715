.header {
  background-color: var(--cui-clr-neutral-900) !important;
  display: flex;
  padding: 1.25rem 1.125rem 1rem;
}
.content {
  max-width: 50rem;
  padding: 0;
  margin: auto;
}
